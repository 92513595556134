import React from 'react';

import UI from '#front-credpago';

import { ReactComponent as AnaliseInstantanea } from '../../images/analise-instantanea.svg';
import { ReactComponent as Clock } from '../../images/clock.svg';
import { Container } from '../partials/style';
import { AnaliseCreditoWrapper, WrapperAnaliseCreditoListItem } from './styles';

const descriptionList = [
  {
    title: 'Retorno em menos de 1 minuto.',
    subtitle: 'Sem burocracia e sem perda de tempo. Economize tempo e recursos automatizando o processo de análise.',
  },

  {
    title: 'Mais segurança ao fechar negócios.',
    subtitle: 'Identifique as melhores alternativas para inquilinos e proprietários e reduza o risco de inadimplência.',
  },

  {
    title: 'Dados dos principais birôs de crédito.',
    subtitle: 'A Análise Instantânea CredPago busca dados dos birôs de crédito mais utilizados no ramo imobiliário.',
  },
];

const AnaliseCredito = () => {
  return (
    <AnaliseCreditoWrapper id="analise-credito">
      <Container className="container">
        <div>
          <AnaliseInstantanea />
          <UI.Title level={1} fontWeight={700} splitLabel={['Análise de crédito', 'sem burocracia']} />
          <p style={{ textAlign: 'justify' }}>
            Com a Análise Instantânea CredPago, inquilino e proprietário têm mais segurança e assertividade para fechar
            negócio. Confira as principais vantagens desta ferramenta:{' '}
          </p>
        </div>
        <div>
          <WrapperAnaliseCreditoListItem>
            {descriptionList.map(({ title, subtitle }, key) => (
              <div key={key}>
                <div>
                  <Clock />
                </div>
                <div>
                  <h1>{title}</h1>
                  <div>{subtitle}</div>
                </div>
              </div>
            ))}
          </WrapperAnaliseCreditoListItem>
        </div>
      </Container>
    </AnaliseCreditoWrapper>
  );
};

export default AnaliseCredito;

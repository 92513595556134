import styled from 'styled-components';

import UI from '#front-credpago';

import globals from '../globals';

const { containerMaxWidth } = globals;

export const Container = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  align-self: center;
  position: relative;

  .margin-top-col {
    margin-top: 110px;
  }

  @media ${UI.globals.mediaqueries.md} {
    .margin-top-col {
      margin-top: 70px;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    .margin-top-col {
      margin-top: 0px;
    }
  }
`;
export const AnaliseCreditoWrapper = styled.section`
  position: relative;
  .container {
    display: grid;
    grid-template-columns: 550px 1fr;
    gap: 360px;
    padding: 15px;
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      display: grid;
      grid-template-columns: 100%;
      gap: 100px;
    }
  }
  p {
    margin-top: 44px;
    margin-right: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #7d7d7d;
  }
  h1 {
    font-size: 72px;
    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      font-size: calc(5px + 7vw);
    }
  }
`;
export const WrapperAnaliseCreditoListItem = styled.div`
  div {
    display: grid;
    grid-template-columns: 1fr 11fr;
    margin-bottom: 51px;
    h1 {
      font-family: 'Uni Neue';
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      color: #003863;
      margin-top: 0;
      margin-bottom: 14px;
    }
    div {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #7d7d7d;
      margin-bottom: 0px;
    }
  }
`;

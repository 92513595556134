import styled from 'styled-components';

import UI from '#front-credpago';

import BgLines from '../../images/cp-lines.png';
import globals from '../globals';

const { fonts, containerMaxWidth } = globals;
export const FullWidthWrapper = styled.section`
  display: flex;
  height: 800px;
  background: url(${BgLines}) no-repeat center center;
  background-size: 100%;
  background-position: bottom;
  position: relative;

  .imagem-fixa-direita {
    position: absolute;
    right: -15px;
    top: 32%;
    width: 50%;
  }

  @media ${UI.globals.mediaqueries.xs} {
    background-image: none;
    margin: 40px auto;
    margin-top: 70px;
    margin-bottom: 0px;

    .imagem-fixa-direita {
      top: 50px;
      right: -8px;
      width: 80%;
    }

    .fundo-go {
      position: absolute;
      margin-top: -120px;
      right: 4px;
      width: 195px;
    }
  }

  @media ${UI.globals.mediaqueries.md} {
    height: ${(props) => (props.smallOnMdScreen ? '700px' : 'inherit')};

    background-size: 100% 55% !important;
    height: 520px !important;
    margin-top: 100px !important;

    .imagem-mao {
      top: 120px !important;
    }

    .imagem-fixa-direita {
      top: 5%;
    }
  }

  @media only screen and (max-width: 767px) {
    height: 755px;
  }

  .text-wrapper {
    align-self: center;

    & > .col-inner {
      @media ${UI.globals.mediaqueries.md} {
        padding-right: 0;
      }

      h1 {
        display: flex;
        flex-direction: column;

        .splitted-span {
          white-space: nowrap;
        }
      }
    }

    p {
      color: #7d7d7d;
      line-height: 1.5;
      margin: 2em 0;
      max-width: 550px;

      @media ${UI.globals.mediaqueries.xs} {
        margin: 1em 0;
      }
    }

    button {
      font-family: ${UI.globals.fonts.default};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: calc(${containerMaxWidth} + 40px);
  margin: 0 auto;
  align-self: center;
  position: relative;

  .margin-top-col {
    margin-top: 110px;
  }

  .imagem-mao {
    position: absolute !important;
    top: 52px;
    left: 35px;
    width: 100%;
    height: auto;
  }

  @media ${UI.globals.mediaqueries.md} {
    .margin-top-col {
      margin-top: 70px;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
    .margin-top-col {
      margin-top: 0px;
    }
  }
`;

export const GarantiaWrapper = styled.section`
  padding-bottom: 75px;

  @media ${UI.globals.mediaqueries.xs} {
    padding-bottom: 0em;
  }

  h2 {
    margin-top: 1em;
    max-width: 800px;
    margin: 0.7em auto;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    padding-bottom: 50px;

    .imagem-fixa-esquerda {
      display: none;
    }
  }

  .picture-wrapper {
    .gatsby-image-wrapper {
      left: -280px;
      width: 202%;
    }

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
      display: none;
    }
  }
`;

export const ListaGarantia = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  font-family: ${fonts.default};
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
  grid-row-gap: 64px;

  @media ${UI.globals.mediaqueries.lg} {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${UI.globals.mediaqueries.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ItemGarantia = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-wrapper {
    margin-bottom: 24px;

    > img {
      width: 75px;
      height: 75px;
    }
  }

  .text-wrapper {
    display: grid;
    grid-template-columns: 64px 2fr;
    grid-gap: 1rem;
    color: #676767;
    line-height: 1.3;

    span.number {
      font-size: 55px;
      line-height: 32px;
      color: #41c0f0;
    }

    span {
      text-align: left;
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

export const ListaVantagens = styled.section`
  width: 100%;
  display: flex;
  padding: 70px 0px;
  padding-bottom: 130px;

  @media ${UI.globals.mediaqueries.xs} {
    padding: 0px;
    margin-top: -80px;
    margin-bottom: 40px;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    display: grid;
    padding-bottom: 40px;
  }
`;

export const ConteudoVantagens = styled.section`
  width: 25%;
  float: left;
  padding: 30px;
  box-shadow: 0px 0px 3px 0px #cccccc;
  border-radius: 30px;
  margin: 0px auto;

  .img-largura {
    max-width: 300px;
    height: 80px;
    margin-left: -120px;
  }

  .img-altura {
    width: 116px;
    margin-top: -47px;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    width: 90%;
    margin: 40px auto;

    .img-largura {
      width: 80%;
      left: 70px;
    }

    .img-altura {
      margin: auto;
      margin-top: -47px;
    }
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.md}, ${UI.globals.mediaqueries.sm} {
    .imagem {
      width: 100% !important;
      margin-bottom: 15px;
    }

    .img-largura {
      margin: auto;
      margin-top: -10px;
      top: -8px;
      left: 0px;
    }

    .img-altura {
      top: -54px;
      margin-top: -8px;
      width: 100px;
    }
  }

  .description-title {
    margin-top: 15px;

    b {
      color: ${UI.globals.colors.brandDarkBlue};
    }
  }
`;

export const AbrangeTitulo = styled.section`
  display: flex;
  margin-bottom: 65px;

  .imagem {
    width: 35%;
    height: 80px;
  }

  .texto {
    text-align: left;
    font-size: 18px;
    width: 100%;
    padding-left: 20%;
    color: ${UI.globals.colors.brandDarkBlue};
    font-weight: 700;
  }

  .texto-mobile {
    display: none;
  }

  @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm}, ${UI.globals.mediaqueries.md} {
    margin-bottom: 40px;
    display: block;

    .texto {
      line-height: 22px !important;
    }

    .texto-desktop {
      display: none;
    }

    .texto-mobile {
      display: block;
    }

    .texto {
      padding-left: 0px;
      text-align: center;
      line-height: 80px;
      font-weight: 900;
    }
  }
`;

import { graphql } from 'gatsby';
import React from 'react';

import AnaliseCredito from '../components/analise-credito/analise-credito';
import BlocoGarantiaCredPago from '../components/garantiaCredpago';
import ServicoCredPago from '../components/garantiaCredpago/servico-credpago';
import HeroPlanosTarifas from '../components/hero/hero-planos-e-tarifas';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const query = graphql`
  query {
    MaoCartao: file(relativePath: { eq: "mao_cartao.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaInquilinos: file(relativePath: { eq: "illustrations/aba-inquilinos.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaImobiliarias: file(relativePath: { eq: "illustrations/aba-imobiliarias.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    AbaProprietarios: file(relativePath: { eq: "illustrations/aba-proprietarios.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    Hero: file(relativePath: { eq: "aluguel-sem-fiador.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    CasalGarantia: file(relativePath: { eq: "casal-garantia.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const AluguelSemFiador = ({ data }) => {
  return (
    <Layout internal={false}>
      <SEO title="Aluguel sem fiador" />
      <ServicoCredPago data={data} />
      <AnaliseCredito />
      <BlocoGarantiaCredPago data={data} />
      <HeroPlanosTarifas data={data} />
    </Layout>
  );
};

export default AluguelSemFiador;

import React from 'react';

import UI from '#front-credpago';

import Icon1 from '../../images/icons/garantia-1.svg';
import Icon2 from '../../images/icons/garantia-2.svg';
import Icon3 from '../../images/icons/garantia-3.svg';
import Icon4 from '../../images/icons/garantia-4.svg';
import Icon5 from '../../images/icons/garantia-5.svg';
import { Container } from '../partials/style';
import { GarantiaWrapper, ItemGarantia as ItemGarantiaWrapper, ListaGarantia } from './styles';

const ItemGarantia = ({ children, icon, alt }) => {
  return (
    <ItemGarantiaWrapper>
      <div className="icon-wrapper">
        <img decoding="async" src={icon} alt={alt} />
      </div>
      <div className="text-wrapper">{children}</div>
    </ItemGarantiaWrapper>
  );
};

const GarantiaCredPago = () => {
  return (
    <GarantiaWrapper id="como-funciona">
      <Container>
        <UI.Row className="main-row">
          <UI.Col style={{ textAlign: 'center' }}>
            <UI.Title
              level={2}
              fontWeight={700}
              splitLabel={['Aluguel rápido e com', null, <br key={0} />, 'análise instantânea']}
              style={{ marginBottom: 45 }}
            />

            <ListaGarantia>
              <ItemGarantia icon={Icon1}>
                <span className="number">#1</span>
                <span>
                  O inquilino procura a <strong>imobiliária parceira</strong> da CredPago e apresenta seu CPF;
                </span>
              </ItemGarantia>

              <ItemGarantia icon={Icon2}>
                <span className="number">#2</span>
                <span>
                  A imobiliária insere os dados em nossa plataforma que{' '}
                  <strong>analisa o cadastro do cliente em menos de 1 minuto</strong> através da Análise Instantânea;
                </span>
              </ItemGarantia>

              <ItemGarantia icon={Icon3}>
                <span className="number">#3</span>
                <span>
                  Se aprovado, o cliente recebe um e-mail para ler e <strong>aceitar os termos</strong> de serviços com
                  a CredPago e prosseguir para o pagamento;
                </span>
              </ItemGarantia>

              <ItemGarantia icon={Icon4} alt="Multi Pagamento">
                <span className="number">#4</span>
                <span>
                  O cliente realiza o pagamento das nossas taxas através do <strong>Multipagamento</strong> podendo
                  parcelar em até 12X no cartão de crédito;
                </span>
              </ItemGarantia>
              <ItemGarantia icon={Icon5}>
                <span className="number">#5</span>
                <span>
                  Pronto! Agora o contrato de aluguel está garantido e é só o cliente retirar as chaves da imobiliária e{' '}
                  <strong>partir para a mudança!</strong>
                </span>
              </ItemGarantia>
            </ListaGarantia>
          </UI.Col>
        </UI.Row>
      </Container>
    </GarantiaWrapper>
  );
};

export default GarantiaCredPago;

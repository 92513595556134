import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

import { Container, FullWidthWrapper } from './styles';

const ServicoCredpago = ({ data }) => {
  return (
    <FullWidthWrapper smallOnMdScreen={true}>
      <div className="imagem-fixa-direita">
        <Img fluid={data.MaoCartao.childImageSharp.fluid} className="imagem-mao" />
      </div>
      <Container>
        <UI.Row>
          <UI.Col className="text-wrapper" responsive={{ xs: 12, md: 6, lg: 6 }}>
            <UI.Title level={1} fontWeight={700} splitLabel={['Sem fiador,', 'Sem pedir favor']} />
            <p style={{ textAlign: 'justify' }}>
              A CredPago atua em parceria com as imobiliárias facilitando a vida de quem procura ou tem imóvel para
              alugar. Nosso principal serviço é o aluguel sem fiador, onde o inquilino paga uma das menores taxas do
              mercado como sua garantia de aluguel.{' '}
            </p>
          </UI.Col>
          <UI.Col responsive={{ xs: 12, md: 6, lg: 6 }} className="fundo-go"></UI.Col>
        </UI.Row>
      </Container>
    </FullWidthWrapper>
  );
};

export default ServicoCredpago;

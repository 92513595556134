import Img from 'gatsby-image';
import React from 'react';

import UI from '#front-credpago';

import SeloFit from '../../images/svg/fit.svg';
import SeloSmart from '../../images/svg/smart.svg';
import SeloSmartPlus from '../../images/svg/smartplus.svg';
import SeloUp from '../../images/svg/up.svg';
import { Container } from '../partials/style';
import {
  BoxWrapper,
  Guarantees,
  HeroPlanosTarifasWrapper,
  ImgGuarantees,
  WarrantyDescription,
  WarrantyInformation,
  WrapperGuarantees,
} from './styles';

const listGuarantees = [
  {
    src: SeloFit,
    alt: 'Produto Fit',
    value: '20x',
    type: 'fit',
  },
  {
    src: SeloSmart,
    alt: 'Produto Smart',
    value: '30x',
    type: 'smart',
  },
  {
    src: SeloSmartPlus,
    alt: 'Produto Smart+',
    value: '35x',
    type: 'smartplus',
  },
  {
    src: SeloUp,
    alt: 'Produto Up',
    value: '40x',
    type: 'up',
  },
];

const HeroPlanosTarifas = ({ data }) => {
  return (
    <HeroPlanosTarifasWrapper id="quanto-custa">
      <Container>
        <UI.Row className="main-row">
          <UI.Col className="picture-wrapper" responsive={{ xs: 12, md: 1, lg: 5 }}>
            <Img fluid={data.Hero.childImageSharp.fluid} />
          </UI.Col>
          <UI.Col responsive={{ xs: 12, md: 9, lg: 7 }}>
            <BoxWrapper>
              <UI.Title
                level={2}
                fontWeight={700}
                splitLabel={['Aluguel sem fiador', <br key={0} />, null, 'e com a maior garantia']}
              />
              <p className="description">
                A garantia para aluguel de imóveis da CredPago é a melhor opção para quem quer se mudar rápido e sem
                depender de ninguém. Já as imobiliárias e proprietários contam com mais segurança através da maior
                cobertura do mercado contra inadimplência. Conheça os planos:
              </p>
              <WrapperGuarantees>
                {listGuarantees.map(({ src, alt, value, type }) => (
                  <Guarantees key={type}>
                    <ImgGuarantees src={src} alt={alt} />
                    <WarrantyInformation type={type}>{value}</WarrantyInformation>
                    <WarrantyDescription>Valor locatício informado</WarrantyDescription>
                  </Guarantees>
                ))}
              </WrapperGuarantees>
              <br />

              <small>* Serviço vai estar disponível de acordo com a estrutura de cada imobiliária.</small>
            </BoxWrapper>
          </UI.Col>
        </UI.Row>
      </Container>
    </HeroPlanosTarifasWrapper>
  );
};

export default HeroPlanosTarifas;
